import React                            from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/tailwind.css';
import './styles/custom.css';

const IndexPage = React.lazy ( () => import('./pages/Index') );
const TermsPage = React.lazy ( () => import('./pages/Terms') );
const PrivacyPage = React.lazy ( () => import('./pages/Privacy') );
const NotFoundPage = React.lazy ( () => import('./pages/NotFound') );

function App() {
	return (
		<>
			<React.Suspense
				fallback={
					<div className={ 'h-screen w-screen flex justify-center items-center bg-brown-accent' } >
					<p className={ 'text-center text-black text-2xl lg:text-9xl font-semibold' } >
						Loading...!
					</p >
				</div > }
			>
				<BrowserRouter >
					<Routes >
						<Route path="/" element={ <IndexPage /> } />
						<Route path="/terms" element={ <TermsPage /> } />
						<Route path="/privacy" element={ <PrivacyPage /> } />
						<Route path="*" element={ <NotFoundPage /> } />
					</Routes >
				</BrowserRouter >
			</React.Suspense >
		</>
	);
}

export default App;
